import { forwardRef } from "react";
import { placeholderImage } from "@ihr-radioedit/inferno-core";
import { generateImageAssetSizes, RESPONSIVE_IMAGE, type Sizes, type SrcSet } from "@inferno/renderer-shared-core";

import { ResponsiveImage } from "../ResponsiveImage.component";
import "../../content-blocks/Asset.style.scss";

interface AssetPlaceholderProps {
  attributeType?: string;
  placeholderSrcset?: SrcSet[];
  sizes?: Sizes[];
  srcset?: SrcSet[];
  alt?: string;
  ref?: React.RefObject<HTMLElement>;
}

export const AssetPlaceholder = forwardRef<HTMLElement, AssetPlaceholderProps>((props, ref) => {
  const { attributeType, srcset, sizes, placeholderSrcset, alt } = props;
  const generatedSizes = generateImageAssetSizes(placeholderImage);

  return (
    <figure ref={ref} className="component-asset-placeholder" data-placeholder-type={attributeType ?? ""}>
      <section className="image-container">
        <ResponsiveImage
          src={placeholderImage}
          srcset={srcset ?? generatedSizes.srcset}
          sizes={sizes ?? generatedSizes.sizes}
          alt={alt || "placeholder image"}
          placeholderSrcset={placeholderSrcset ?? generatedSizes.placeholderSrcset}
          initialWidth={RESPONSIVE_IMAGE.responsiveImageInitialWidth}
          initialHeight={RESPONSIVE_IMAGE.responsiveImageInitialHeight}
        />
      </section>
    </figure>
  );
});

import { useEffect, useState, useCallback, useRef } from "react";
import { inject } from "mobx-react";

import { type AssetInterface, ILog, noop } from "@ihr-radioedit/inferno-core";
import { type Store, type BaseStore, type TagsEvent, isWindowDefined } from "@inferno/renderer-shared-core";

import { useElementOnScreen } from "../lib/embed";
import { AssetPlaceholder } from "../components/placeholders/AssetPlaceholder";

const log = ILog.logger("EmbedAssetLegacyComponent");

type ScriptProviderScriptParams = (param: any) => {
  async?: boolean | undefined;
  defer?: boolean | undefined;
  src: string;
  type: string;
  class?: string | undefined;
  html?: string | undefined;
  height: string | number;
  width: string | number;
};

type ScriptProviderHtmlParams = (param: any) => string;

export type ScriptProvider = {
  meta: {
    scriptInjection: "dynamic" | "preloaded";
    requires: string[];
    params: {
      [key: string]: string;
    };
  };
  script: ScriptProviderScriptParams;
  html: ScriptProviderHtmlParams;
  mobileScript: ScriptProviderScriptParams | null;
  mobileHtml: ScriptProviderHtmlParams | null;
  refresh: (param: any) => void | null;
  load: () => void | null;
  destroy: () => void | null;
};

export interface EmbedAssetLegacyProps extends AssetInterface {
  store?: Store | BaseStore;
  attributes_provider: ScriptProvider;
  html: string;
  provider: string;
}

const makeParams = (provider: string, params: object, store: Store | BaseStore | undefined) => {
  return {
    [provider]: { ...params },
    metaOgUrl: store?.tags?.metadata?.get("meta-property-og:url")?.value,
  };
};

/**
 * Custom block thats only supported by inferno and NOT RadioEdit
 */
export const EmbedAssetLegacy = inject("store")((props: EmbedAssetLegacyProps) => {
  if (!props.store) {
    return null;
  }

  const [isSdkLoaded, setIsSdkLoaded] = useState(false);
  const [isMetaDataSet, setIsMetaDataSet] = useState(false);
  const [targetContainer, setTargetContainer] = useState("");
  const [isVideoInitialized, setVideoInitialized] = useState(false);
  const scriptRef = useRef<HTMLScriptElement | null>(null);
  const videoInitHandlerRef = useRef<(_event: TagsEvent) => void>(noop);

  const [containerRef, isElementViewable] = useElementOnScreen<HTMLDivElement>({
    rootMargin: "0px",
    threshold: 0,
  });

  const loadScript = useCallback(
    scriptParams => {
      const params =
        scriptParams ||
        props.attributes_provider.script(
          makeParams(props.provider, props.attributes_provider.meta.params, props.store),
        );

      const script = document.createElement("script");

      Object.entries(params).forEach(([key, value]) => {
        if (script) {
          if (key === "async" && value === true) {
            script.async = true;
          } else if (key === "defer" && value === true) {
            script.defer = true;
          } else {
            script.setAttribute(key, value as string);
          }
        }
      });

      /**
       * This doesn't imply that video is ready/visible to the screen..
       * As of right now, this is the only notification we have- that the video is ready to be played
       * (even though it's probably not, which is why the video causes layout shift when the placeholder goes away too early)
       */
      script.onload = () => {
        if (params && "src" in params) {
          log.info("Embed Asset Component Loaded", params.src);
        }
        setIsSdkLoaded(true);
      };

      script.onerror = () => {
        if (params && "src" in params) {
          log.error("Error loading Embed Asset Component", params.src);
        }
      };

      window.document.body.appendChild(script);
      scriptRef.current = script;
    },
    [props.provider, props.attributes_provider, props.store],
  );

  const videoInitHandler = useCallback(
    (_event: TagsEvent) => {
      /* Add this check for when meta tags are ready but element is not viewable yet */
      if (!isElementViewable) {
        setIsMetaDataSet(true);
        return;
      }

      if (isVideoInitialized || isSdkLoaded || targetContainer) {
        return;
      }

      const updatedParams = makeParams(props.provider, props.attributes_provider.meta.params, props.store);
      log.info(`videoInitHandler - dispatch from: ${_event.from}:`, updatedParams);

      let containerSetter = props.attributes_provider.html;
      let scriptLoader = props.attributes_provider.script;

      if (
        props.store?.device.deviceType === "mobile" &&
        props.attributes_provider.mobileHtml &&
        props.attributes_provider.mobileScript
      ) {
        containerSetter = props.attributes_provider.mobileHtml;
        scriptLoader = props.attributes_provider.mobileScript;
      }

      if (containerSetter) {
        setTargetContainer(containerSetter(updatedParams));
      }

      const scriptParams = scriptLoader(updatedParams);
      if (scriptParams?.src) {
        log.debug("videoInitHandler - script:");
        loadScript(scriptParams);
      } else {
        log.debug("videoInitHandler - script preloaded:");
        setIsSdkLoaded(true);
      }

      setVideoInitialized(true);
      setIsMetaDataSet(true);
    },
    [
      loadScript,
      props.attributes_provider,
      isElementViewable,
      isVideoInitialized,
      isSdkLoaded,
      targetContainer,
      props.provider,
      props.store,
    ],
  );
  videoInitHandlerRef.current = videoInitHandler;

  useEffect(
    function initializeVideoOnView() {
      /* this is for when onStoreTagsAction was already dispatched, but element is NOW viewable */
      if (isElementViewable && isMetaDataSet && !isVideoInitialized && !isSdkLoaded && !targetContainer) {
        log.debug("initializeVideoOnView:");
        videoInitHandlerRef.current({ ready: true, from: "EmbedAssetLegacy", newState: {} });
        return;
      }
    },
    [isSdkLoaded, targetContainer, isVideoInitialized, isElementViewable, isMetaDataSet],
  );

  useEffect(
    function clientSideRefresh() {
      /* Some sdk/scripts are loaded once on page load (ex: outbrain, exco);
       * So we need to manually refresh their player for client side navigations
       */
      const params = makeParams(props.provider, props.attributes_provider.meta.params, props.store);

      if (isWindowDefined() && window.CLIENT_NAVIGATION === true && isSdkLoaded && targetContainer) {
        log.debug("clientSideRefresh:", params);
        if (props.attributes_provider?.refresh) {
          props.attributes_provider?.refresh(params);
        } else if (props.attributes_provider?.load) {
          props.attributes_provider?.load();
        }
      }
    },
    [isSdkLoaded, targetContainer, props.attributes_provider, props.provider, props.store],
  );

  useEffect(
    function setupSubscriptionsAndCleanup() {
      /* Establish a subscription before meta tags are ready */
      props.store?.onStoreTagsAction.subscribe(videoInitHandlerRef.current);

      /* Establish a subscription before the static embed script is loaded;
       * We know that the meta tags was already dispatched prior to this being called-- so it's safe to load the video right away
       */
      if (props.attributes_provider?.load) {
        props.store?.onEmbedScriptAction.subscribe(props.attributes_provider.load);
      }

      return () => {
        if (videoInitHandlerRef.current) {
          props.store?.onStoreTagsAction.unsubscribe(videoInitHandlerRef.current);
        }

        if (props.attributes_provider?.load) {
          props.store?.onEmbedScriptAction.unsubscribe(props.attributes_provider.load);
        }

        if (scriptRef.current) {
          window.document.body.removeChild(scriptRef.current);
        }

        /* Some providers require a manual destroy for the video */
        if (props.attributes_provider?.destroy) {
          props.attributes_provider.destroy();
        }
      };
    },
    [props.attributes_provider, props.store.onStoreTagsAction, props.store.onEmbedScriptAction],
  );

  const { width = "100%", height = "100%" } =
    props.attributes_provider?.script(makeParams(props.provider, props.attributes_provider.meta.params, props.store)) ||
    {};
  const containerStyles: React.CSSProperties = {
    width,
    height,
  };

  const embedStyles: React.CSSProperties = {
    ...containerStyles,
    visibility: isSdkLoaded ? "visible" : "hidden",
    opacity: isSdkLoaded ? "1" : "0",
  };

  return (
    <div className="component-embed-asset-legacy" style={containerStyles} ref={containerRef}>
      <div style={embedStyles} dangerouslySetInnerHTML={{ __html: targetContainer }} />
      {!isSdkLoaded && <AssetPlaceholder />}
    </div>
  );
});

export default EmbedAssetLegacy;
